import { nanoid } from 'nanoid';
import { z } from 'zod';
import { slugify, nanoidSchema } from '../utility/index.js';

const themeSchema = z.preprocess((arg) => {
    if (!arg)
        return arg;
    if (typeof arg === 'object')
        arg.identifier = slugify(arg.name);
    return arg;
}, z.object({
    uid: nanoidSchema,
    name: z.string().transform(slugify),
    description: z.string(),
    theme: z.string(),
    identifier: z.string().transform(slugify),
}));
const defaultTheme = (cssString = '') => ({
    uid: nanoid(),
    name: 'Default Theme',
    identifier: 'default',
    description: '',
    theme: cssString,
});

export { defaultTheme, themeSchema };
